import React from 'react';

import Head from 'next/head';
import sanitizeHtml from 'sanitize-html';

import { CANONICAL_ROOT_URL } from '../../constants/constants';

interface IProps {
  title?: string;
  image?: string;
  description?: string;
  robots?: string;
  ogTitle?: string;
  link?: string;
  schema?: string;
}

const getValidatedLink = (link: string): string => {
  const absoluteURL = link.startsWith('http');
  const validatedLink = absoluteURL ? link : `${CANONICAL_ROOT_URL}${link}`;
  if (absoluteURL && !validatedLink.includes('https://')) {
    throw new Error(`MetaTags link defined as an unsecure URL: ${validatedLink}`);
  }
  return validatedLink;
};

export const MetaTags = ({ title, image, description, robots, link, schema, ogTitle }: IProps) => {
  const validatedLink = link && getValidatedLink(link);

  return (
    <Head>
      {schema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(schema) }}
        />
      )}
      {title && <title>{title}</title>}
      {(ogTitle || title) && <meta property="og:title" content={ogTitle || title} />}
      {image && <meta property="og:image" content={image} />}
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="description" content={description} />}
      {robots && <meta name="robots" content={robots} />}
      <meta property="og:type" content="article" />
      {validatedLink && <meta property="og:url" content={validatedLink} />}
      {validatedLink && <link rel="canonical" href={validatedLink} />}
    </Head>
  );
};
