import React from 'react';

import { IIconProps } from './constants';

export const StarHalfIcon = ({ height = 24, width = 24 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7947 8.65748C14.8678 8.80431 15.0083 8.90596 15.1706 8.92948L20.6895 9.72933C20.8285 9.74947 20.9409 9.84548 20.9826 9.97311C21.0242 10.1003 20.9899 10.2406 20.8916 10.3356L16.8988 14.1917C16.7797 14.3067 16.7252 14.4733 16.7535 14.6365L17.6958 20.0841C17.7185 20.2156 17.6644 20.3502 17.5526 20.4308C17.4402 20.5118 17.2901 20.5227 17.1665 20.4582L12.2312 17.8848C12.0863 17.8092 11.9137 17.8092 11.7688 17.8848L6.83354 20.4582C6.7099 20.5227 6.55982 20.5118 6.44739 20.4308C6.33558 20.3502 6.28148 20.2156 6.30422 20.0841L7.24652 14.6365C7.27475 14.4733 7.22032 14.3067 7.10118 14.1917L3.10842 10.3356C3.01009 10.2406 2.97579 10.1003 3.01737 9.97311C3.0591 9.84548 3.17153 9.74947 3.31047 9.72933L8.82941 8.92948C8.99173 8.90596 9.1322 8.80431 9.20529 8.65748L11.6732 3.70012C11.7334 3.57916 11.8593 3.5 12 3.5C12.1407 3.5 12.2666 3.57916 12.3268 3.70012L14.7947 8.65748Z"
      className="stroke-current"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18.3281L7.06472 20.9016C6.77375 21.0533 6.42113 21.0281 6.15515 20.8365C5.88917 20.6449 5.75596 20.3202 5.81154 19.9989L6.75384 14.5513L2.76107 10.6953C2.52563 10.4679 2.44076 10.1277 2.54213 9.81771C2.6435 9.50771 2.91354 9.28163 3.23875 9.2345L8.75769 8.43465L11.2256 3.4773C11.3711 3.18503 11.6713 3 12 3V18.3281Z"
      className="fill-current"
    />
  </svg>
);
