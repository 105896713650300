import React, { useRef } from 'react';

import { formatDate } from '../../../helpers/date';
import { useLineClamp } from '../../../helpers/hooks/useLineClamp';
import { getUserUrl } from '../../../helpers/urls';
import { Button } from '../../Clickables/Buttons/Button';
import { InternalLink } from '../../Clickables/InternalLink/InternalLink';
import { Heading } from '../../Heading/Heading';
import { Modal } from '../../Modal/Modal';
import { ModalBody } from '../../Modal/ModalBody';
import { Rating, RatingVariant } from '../../Rating/Rating';
import { Text } from '../../Text/Text';

import styles from './ReviewCard.module.css';

export interface IReviewProps {
  rating: number;
  username: string;
  creationDate?: string;
  heading?: string | null;
  bodyText?: string | null;
  lightBackground?: boolean;
  nonLinking?: boolean;
  className?: string;
  showAllText?: boolean;
  maxLinesNumber?: number;
}

export const ReviewCard = ({
  rating,
  heading,
  bodyText,
  username,
  creationDate,
  lightBackground = true,
  nonLinking = false,
  className = '',
  showAllText = false,
  maxLinesNumber = 4,
}: IReviewProps) => {
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const { accommodatedCharactersNumber } = useLineClamp(paragraphRef);
  const isFullTextAboveCharacterLimit = (bodyText?.length ?? 0) > accommodatedCharactersNumber;

  return (
    <div
      data-testid="reviewCard"
      className={`${
        lightBackground ? 'bg-lake-fog' : 'bg-sea-foam'
      } rounded-inline-card mt-6 flex flex-col justify-between p-4 ${className}`}
    >
      <div>
        <Rating
          data-testid="starsInReviewCard"
          className="pb-3"
          rating={rating}
          variant={RatingVariant.allStars}
          hideLabel
        />
        {heading && (
          <Heading
            className={`${styles.breakWordText} mb-1 text-ellipsis ${
              showAllText ? '' : 'line-clamp-1'
            }`}
            variant="2xs"
            title={heading}
            data-testid="reviewTitle"
          >
            {heading}
          </Heading>
        )}

        {bodyText && (
          <div className="flex items-stretch">
            <div className="flex w-full flex-col">
              {showAllText ? (
                <Text
                  variant="sm"
                  data-testid="reviewDescription"
                  className="mb-1 mt-0.5 whitespace-pre-line break-words font-heading"
                >
                  {bodyText}
                </Text>
              ) : (
                <>
                  {accommodatedCharactersNumber === Infinity ? (
                    <p
                      ref={paragraphRef}
                      className={`line-clamp-${maxLinesNumber} overflow-hidden whitespace-pre-line break-words font-heading`}
                    >
                      {bodyText}
                    </p>
                  ) : (
                    <Text
                      variant="sm"
                      className={`line-clamp-${maxLinesNumber} mb-1 mt-0.5 hyphens-auto whitespace-pre-line break-words font-heading`}
                      data-testid="reviewDescription"
                    >
                      {bodyText}
                    </Text>
                  )}
                  <Text variant="sm" className="font-heading">
                    {isFullTextAboveCharacterLimit && (
                      <>
                        {' '}
                        <Modal
                          trigger={props => (
                            <Button variant="textPrimary" {...props}>
                              Read More
                            </Button>
                          )}
                        >
                          <ModalBody>
                            <Rating
                              className="mb-3"
                              rating={rating}
                              variant={RatingVariant.allStars}
                            />
                            {heading && (
                              <Heading variant="xs" className="mb-3" title={heading}>
                                {heading}
                              </Heading>
                            )}
                            <Text className="mb-3 whitespace-pre-line break-words">{bodyText}</Text>
                            <div className="flex flex-row">
                              <Text className="mb-0 mr-auto" variant="sm">
                                {username}
                              </Text>

                              {creationDate && (
                                <Text className="mb-0 ml-4 text-midnight" variant="sm">
                                  {formatDate(creationDate)}
                                </Text>
                              )}
                            </div>
                          </ModalBody>
                        </Modal>
                      </>
                    )}
                  </Text>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="body-text-sm mt-2 flex flex-wrap justify-between">
          <InternalLink
            to={nonLinking ? '' : getUserUrl(username)}
            variant="textPrimary"
            data-testid="reviewer"
            className={`${nonLinking ? 'pointer-events-none' : ''} line-clamp-1 break-all`}
          >
            {username}
          </InternalLink>
          {creationDate && (
            <div data-testid="reviewDate" className="body-text-sm text-abyss">
              {formatDate(creationDate)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
