import React from 'react';

import { IIconProps } from './constants';

export const StarIcon = ({ height = 24, width = 24 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C12.3287 3 12.6289 3.18503 12.7744 3.4773L15.2423 8.43465L20.7612 9.2345C21.0865 9.28163 21.3565 9.50771 21.4579 9.81771C21.5592 10.1277 21.4744 10.4679 21.2389 10.6953L17.2462 14.5513L18.1885 19.9989C18.244 20.3202 18.1108 20.6449 17.8448 20.8365C17.5789 21.0281 17.2263 21.0533 16.9353 20.9016L12 18.3281L7.06472 20.9016C6.77375 21.0533 6.42113 21.0281 6.15515 20.8365C5.88917 20.6449 5.75596 20.3202 5.81154 19.9989L6.75384 14.5513L2.76107 10.6953C2.52563 10.4679 2.44076 10.1277 2.54213 9.81771C2.6435 9.50771 2.91354 9.28163 3.23875 9.2345L8.75769 8.43465L11.2256 3.4773C11.3711 3.18503 11.6713 3 12 3Z"
      className="fill-current"
    />
  </svg>
);
