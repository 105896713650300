import React from 'react';

import { Entry } from 'contentful';

import { MILLION_USERS } from '../../constants/constants';
import { useIsMobileScreenWidthOrThinner } from '../../helpers/hooks/useScreenWidth';
import { TypeReviewSectionSkeleton } from '../../interfaces/contentful';
import { AppRatingSection } from '../AppRatingSection/AppRatingSection';
import { ReviewCard } from '../Cards/ReviewCard/ReviewCard';
import { ContinuousCarouselSimplified } from '../Carousels/ContinuousCarouselSimplified/ContinuousCarouselSimplified';
import { AppStoreButtons } from '../Clickables/Buttons/AppStoreButtons/AppStoreButtons';
import { Container } from '../Container/Container';
import { Heading } from '../Heading/Heading';
import { QRCode } from '../QRCode/QRCode';
import { Text } from '../Text/Text';

import { defaultAppReviews } from './constants';

interface IProps {
  cmsReviews?: Entry<TypeReviewSectionSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>;
  lightBackground?: boolean;
}

const MobileReviews = ({ cmsReviews, lightBackground = true }: IProps) => (
  <Container>
    {cmsReviews?.fields.reviews?.map(review => {
      if (!review) {
        return <></>;
      }
      const { starRating, username, reviewBody, reviewTitle } = review.fields;
      const _id = review.sys.id;
      return (
        <ReviewCard
          key={_id}
          rating={starRating}
          username={username}
          bodyText={reviewBody}
          heading={reviewTitle}
          showAllText
          nonLinking
          lightBackground={lightBackground}
          className="w-col-4 sm:w-col-6 md:w-col-3 min-w-col-4 sm:min-w-col-6 md:min-w-col-3 mb-5 h-fit snap-center"
        />
      );
    })}
    {!cmsReviews?.fields.reviews &&
      defaultAppReviews.map(review => (
        <ReviewCard
          {...review}
          key={review.key}
          showAllText
          nonLinking
          lightBackground={lightBackground}
          className="w-col-4 sm:w-col-6 md:w-col-3 min-w-col-4 sm:min-w-col-6 md:min-w-col-3 mb-5 h-fit snap-center"
        />
      ))}
  </Container>
);

const TabletAndDesktopReviews = ({ cmsReviews, lightBackground = true }: IProps) => (
  <ContinuousCarouselSimplified>
    {cmsReviews?.fields.reviews?.map(review => {
      if (!review) {
        return <></>;
      }
      const { starRating, username, reviewBody, reviewTitle } = review.fields;
      const _id = review.sys.id;
      return (
        <ReviewCard
          key={_id}
          rating={starRating}
          username={username}
          bodyText={reviewBody}
          heading={reviewTitle}
          showAllText
          nonLinking
          lightBackground={lightBackground}
          className="w-col-4 sm:w-col-6 md:w-col-3 min-w-col-4 sm:min-w-col-6 md:min-w-col-3 mb-5 h-fit snap-center"
        />
      );
    })}
    {!cmsReviews?.fields.reviews &&
      defaultAppReviews.map(review => (
        <ReviewCard
          {...review}
          key={review.key}
          showAllText
          nonLinking
          lightBackground={lightBackground}
          className="w-col-4 sm:w-col-6 md:w-col-3 min-w-col-4 sm:min-w-col-6 md:min-w-col-3 mb-5 h-fit snap-center"
        />
      ))}
  </ContinuousCarouselSimplified>
);

export const ReviewSectionList = ({ cmsReviews, lightBackground = true }: IProps) => {
  const isMobileScreenWidthOrThinner = useIsMobileScreenWidthOrThinner();

  return (
    <section data-testid="ReviewSectionList" className="py-sm">
      <Container className="mb-8">
        <Heading className="mb-6 text-center md:mb-8">
          {cmsReviews?.fields.reviewTitle
            ? cmsReviews.fields.reviewTitle
            : `Join ${MILLION_USERS} million anglers on Fishbrain`}
        </Heading>
        <AppRatingSection bottomOfPage />
      </Container>

      {isMobileScreenWidthOrThinner && (
        <MobileReviews cmsReviews={cmsReviews} lightBackground={lightBackground} />
      )}
      {!isMobileScreenWidthOrThinner && (
        <TabletAndDesktopReviews cmsReviews={cmsReviews} lightBackground={lightBackground} />
      )}
      {!cmsReviews && (
        <Container>
          <div className="mt-10 flex flex-col items-center">
            <div className="flex space-x-4">
              <QRCode source="homePageReviewSection" />
              <AppStoreButtons source="homePageReviewSection" orientation="vertical" />
            </div>

            <Text variant="xs" className="mt-4 font-semibold">
              Free trial available
            </Text>
          </div>
        </Container>
      )}
    </section>
  );
};
