import ContentLoader from 'react-content-loader';

import { RATING_ICON_WIDTH, RATING_ICON_WIDTH_LARGE } from '../constants/constants';

interface IProps {
  isLarge?: boolean;
}

export const RatingLoader = ({ isLarge = false }: IProps) => {
  return (
    <ContentLoader
      uniqueKey="rating-loader-key"
      className=""
      style={{
        width: isLarge ? `${RATING_ICON_WIDTH_LARGE * 5}px` : `${RATING_ICON_WIDTH * 5}px`,
        height: isLarge ? `${RATING_ICON_WIDTH_LARGE}px` : `${RATING_ICON_WIDTH}px`,
      }}
    >
      <rect
        x="0"
        y="0"
        rx="5"
        ry="5"
        width={isLarge ? RATING_ICON_WIDTH_LARGE * 5 : RATING_ICON_WIDTH * 5}
        height={isLarge ? RATING_ICON_WIDTH_LARGE : RATING_ICON_WIDTH}
      />
    </ContentLoader>
  );
};
