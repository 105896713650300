import React from 'react';

import styles from '../Carousel.module.css';

/*
  This carousel is designed to be used where only the inner component is needed,
  on a touch screen where we do not need to show buttons.

  Examples include the review section where we have reviews in a carousel
  only on a tablet screen

  Do not use this carousel on a desktop screen - there are no buttons!
*/

interface IProps {
  children: React.ReactNode;
}

export const ContinuousCarouselSimplified = ({ children }: IProps) => (
  <div
    className={`${styles.container} flex snap-x space-x-4 overflow-y-hidden overflow-x-scroll scroll-smooth pl-11 md:justify-center`}
    data-testid="HomePageCarousel"
  >
    {/* Apply 'snap-center' class to child product cards */}
    {children}
    {/* The below line is a bit of a hack to get around the fact that overflow-scroll won't recognise padding-right */}
    <div className="pl-6 sm:pl-11 md:pl-14 lg:pl-16" />
  </div>
);
